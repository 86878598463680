import { Capacitor } from '@capacitor/core';
import { version } from '../../package.json';

const isWebVersion = Capacitor.getPlatform() === 'web';

const enableAccountCreation = !isWebVersion;

export const environment = {
  firebase: {
    projectId: 'tractorpal-376b0',
    appId: '1:880174013940:web:13507d50a99c22fbc52da1',
    databaseURL: 'https://tractorpal-376b0.firebaseio.com',
    storageBucket: 'tractorpal-376b0.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyA9zUN_339mSS3GPJ48rJEoVHuZjK6NeLI',
    authDomain: 'tractorpal-376b0.firebaseapp.com',
    messagingSenderId: '880174013940',
    measurementId: 'G-E8L30XP16B',
  },
  production: true,
  enableAccountCreation,
  isWebVersion,
  version,
  mixpanelToken: '039e97cfcf90c163c2cd644c53cbf4dc',
  sentryDsn:
    'https://2149ad3a2ba64841ba1a58155f8e5c46@o355383.ingest.sentry.io/2714057',
  appleClientId: isWebVersion
    ? 'com.live.tractorpal2.web'
    : 'com.live.tractorpal2',
};
