{
  "name": "tractorpal",
  "description": "TractorPal: A Personal Inventory and Maintenance Tracker",
  "version": "3.2.2",
  "author": "TractorPal",
  "homepage": "http://www.tractorpal.com/",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "prepare-production": "./scripts/prepare-production.sh",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "NODE_OPTIONS=--max_old_space_size=8048 ng lint",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org tractorpal --project tractorpal-app ./www && sentry-cli sourcemaps upload --org tractorpal --project tractorpal-app ./www"
  },
  "private": true,
  "dependencies": {
    "@angular/common": "^18.1.3",
    "@angular/core": "^18.1.3",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "^18.1.3",
    "@angular/platform-browser": "^18.1.3",
    "@angular/platform-browser-dynamic": "^18.1.3",
    "@angular/router": "^18.1.3",
    "@capacitor-community/apple-sign-in": "^6.0.0",
    "@capacitor-community/facebook-login": "^6.0.0",
    "@capacitor/android": "6.1.0",
    "@capacitor/angular": "^2.0.3",
    "@capacitor/camera": "^6.0.1",
    "@capacitor/core": "6.1.0",
    "@capacitor/filesystem": "^6.0.0",
    "@capacitor/haptics": "6.0.0",
    "@capacitor/ios": "6.1.0",
    "@capacitor/keyboard": "6.0.1",
    "@capacitor/share": "^6.0.1",
    "@capacitor/splash-screen": "^6.0.1",
    "@ionic/angular": "^8.3.0",
    "@ionic/core": "^8.3.0",
    "@maskito/angular": "^3.0.0",
    "@maskito/core": "^3.0.0",
    "@maskito/kit": "^3.0.0",
    "@revenuecat/purchases-capacitor": "^9.2.1",
    "@sentry/angular": "^9.1.0",
    "date-fns": "^2.30.0",
    "firebase-admin": "^12.6.0",
    "ionicons": "^7.4.0",
    "lodash-es": "^4.17.21",
    "mixpanel-browser": "^2.60.0",
    "ngx-extended-pdf-viewer": "^21.1.0",
    "pdfmake": "^0.2.10",
    "rxjs": "~7.8.1",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.3",
    "@angular-eslint/builder": "18.2.0",
    "@angular-eslint/eslint-plugin": "18.2.0",
    "@angular-eslint/eslint-plugin-template": "18.2.0",
    "@angular-eslint/schematics": "18.2.0",
    "@angular-eslint/template-parser": "18.2.0",
    "@angular/cli": "^18.1.3",
    "@angular/compiler": "^18.1.3",
    "@angular/compiler-cli": "^18.1.3",
    "@angular/language-service": "^18.1.3",
    "@capacitor/assets": "^3.0.5",
    "@capacitor/cli": "6.1.0",
    "@ionic/angular-toolkit": "^11.0.1",
    "@ionic/cli": "^7.2.0",
    "@playwright/test": "^1.48.1",
    "@types/jasmine": "~4.0.0",
    "@types/lodash-es": "^4.17.8",
    "@types/mixpanel-browser": "^2.51.0",
    "@types/node": "^22.7.7",
    "@types/pdfmake": "^0.2.9",
    "@typescript-eslint/eslint-plugin": "8.0.0",
    "@typescript-eslint/parser": "8.0.0",
    "eslint": "^8.57.0",
    "eslint-plugin-deprecation": "^2.0.0",
    "eslint-plugin-import": "2.29.1",
    "firebase-tools": "^13.29.3",
    "ios-deploy": "^1.12.2",
    "jasmine-core": "~4.3.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "native-run": "^1.7.2",
    "prettier": "^3.2.5",
    "source-map-explorer": "^2.5.3",
    "typescript": "~5.5.4"
  },
  "browserslist": [
    "Chrome >=79",
    "ChromeAndroid >=79",
    "Firefox >=70",
    "Edge >=79",
    "Safari >=14",
    "iOS >=14"
  ]
}